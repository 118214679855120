import"core-js/modules/web.dom-collections.iterator.js";import"core-js/modules/web.url.js";import"core-js/modules/web.url-search-params.js";import"core-js/modules/es.array.includes.js";import"core-js/modules/es.string.replace.js";import{Map as ImmutableMap,List as ImmutableList,OrderedSet as ImmutableOrderedSet,fromJS}from'immutable';import{createSelector}from'reselect';import{getSettings}from'soapbox/actions/settings';import{getDomain}from'soapbox/utils/accounts';import{validId}from'soapbox/utils/auth';import ConfigDB from'soapbox/utils/config_db';import{shouldFilter}from'soapbox/utils/timelines';const normalizeId=id=>typeof id==='string'?id:'';const getAccountBase=(state,id)=>state.accounts.get(id);const getAccountCounters=(state,id)=>state.accounts_counters.get(id);const getAccountRelationship=(state,id)=>state.relationships.get(id);const getAccountMoved=(state,id)=>{var _state$accounts$get;return state.accounts.get(((_state$accounts$get=state.accounts.get(id))===null||_state$accounts$get===void 0?void 0:_state$accounts$get.moved)||'');};const getAccountMeta=(state,id)=>state.accounts_meta.get(id);const getAccountAdminData=(state,id)=>state.admin.users.get(id);const getAccountPatron=(state,id)=>{var _state$accounts$get2;const url=(_state$accounts$get2=state.accounts.get(id))===null||_state$accounts$get2===void 0?void 0:_state$accounts$get2.url;return url?state.patron.accounts.get(url):null;};export const makeGetAccount=()=>{return createSelector([getAccountBase,getAccountCounters,getAccountRelationship,getAccountMoved,getAccountMeta,getAccountAdminData,getAccountPatron],(base,counters,relationship,moved,meta,admin,patron)=>{if(!base)return null;return base.withMutations(map=>{if(counters)map.merge(counters);if(meta){map.merge(meta);map.set('pleroma',meta.pleroma.merge(base.get('pleroma',ImmutableMap())));// Lol, thanks Pleroma
}if(relationship)map.set('relationship',relationship);map.set('moved',moved||null);map.set('patron',patron||null);map.setIn(['pleroma','admin'],admin);});});};const findAccountsByUsername=(state,username)=>{const accounts=state.accounts;return accounts.filter(account=>{return username.toLowerCase()===account.acct.toLowerCase();});};export const findAccountByUsername=(state,username)=>{const accounts=findAccountsByUsername(state,username);if(accounts.size>1){var _state$accounts$get3;const me=state.me;const meURL=((_state$accounts$get3=state.accounts.get(me))===null||_state$accounts$get3===void 0?void 0:_state$accounts$get3.url)||'';return accounts.find(account=>{try{// If more than one account has the same username, try matching its host
const{host}=new URL(account.url);const{host:meHost}=new URL(meURL);return host===meHost;}catch{return false;}});}else{return accounts.first();}};const toServerSideType=columnType=>{switch(columnType){case'home':case'notifications':case'public':case'thread':return columnType;default:if(columnType.indexOf('list:')>-1){return'home';}else{return'public';// community, account, hashtag
}}};export const getFilters=(state,query)=>{return state.filters.filter(filter=>{return(query===null||query===void 0?void 0:query.contextType)&&filter.context.includes(toServerSideType(query.contextType))&&(filter.expires_at===null||Date.parse(filter.expires_at)>new Date().getTime());});};const escapeRegExp=string=>string.replace(/[.*+?^${}()|[\]\\]/g,'\\$&');// $& means the whole matched string
export const regexFromFilters=filters=>{if(filters.size===0)return null;return new RegExp(filters.map(filter=>{let expr=escapeRegExp(filter.get('phrase'));if(filter.get('whole_word')){if(/^[\w]/.test(expr)){expr="\\b".concat(expr);}if(/[\w]$/.test(expr)){expr="".concat(expr,"\\b");}}return expr;}).join('|'),'i');};export const makeGetStatus=()=>{return createSelector([(state,_ref)=>{let{id}=_ref;return state.statuses.get(id);},(state,_ref2)=>{var _state$statuses$get;let{id}=_ref2;return state.statuses.get(((_state$statuses$get=state.statuses.get(id))===null||_state$statuses$get===void 0?void 0:_state$statuses$get.reblog)||'');},(state,_ref3)=>{var _state$statuses$get2;let{id}=_ref3;return state.accounts.get(((_state$statuses$get2=state.statuses.get(id))===null||_state$statuses$get2===void 0?void 0:_state$statuses$get2.account)||'');},(state,_ref4)=>{var _state$statuses$get3,_state$statuses$get4;let{id}=_ref4;return state.accounts.get(((_state$statuses$get3=state.statuses.get(((_state$statuses$get4=state.statuses.get(id))===null||_state$statuses$get4===void 0?void 0:_state$statuses$get4.reblog)||''))===null||_state$statuses$get3===void 0?void 0:_state$statuses$get3.account)||'');},(_state,_ref5)=>{let{username}=_ref5;return username;},getFilters,state=>state.me],(statusBase,statusReblog,accountBase,accountReblog,username,filters,me)=>{var _statusReblog;if(!statusBase||!accountBase)return null;const accountUsername=accountBase.acct;//Must be owner of status if username exists
if(accountUsername!==username&&username!==undefined){return null;}if(statusReblog&&accountReblog){// @ts-ignore AAHHHHH
statusReblog=statusReblog.set('account',accountReblog);}else{statusReblog=undefined;}const regex=(accountReblog||accountBase).id!==me&&regexFromFilters(filters);const filtered=regex&&regex.test(((_statusReblog=statusReblog)===null||_statusReblog===void 0?void 0:_statusReblog.search_index)||statusBase.search_index);return statusBase.withMutations(map=>{map.set('reblog',statusReblog||null);// @ts-ignore :(
map.set('account',accountBase||null);map.set('filtered',Boolean(filtered));});});};export const makeGetNotification=()=>{return createSelector([(_state,notification)=>notification,(state,notification)=>state.accounts.get(normalizeId(notification.account)),(state,notification)=>state.accounts.get(normalizeId(notification.target)),(state,notification)=>state.statuses.get(normalizeId(notification.status))],(notification,account,target,status)=>{return notification.merge({// @ts-ignore
account:account||null,// @ts-ignore
target:target||null,// @ts-ignore
status:status||null});});};export const getAccountGallery=createSelector([(state,id)=>state.timelines.getIn(["account:".concat(id,":media"),'items'],ImmutableList()),state=>state.statuses,state=>state.accounts],(statusIds,statuses,accounts)=>{return statusIds.reduce((medias,statusId)=>{const status=statuses.get(statusId);if(!status)return medias;if(status.reblog)return medias;if(typeof status.account!=='string')return medias;const account=accounts.get(status.account);return medias.concat(status.media_attachments.map(media=>media.merge({status,account})));},ImmutableList());});export const makeGetChat=()=>{return createSelector([(state,_ref6)=>{let{id}=_ref6;return state.chats.items.get(id);},(state,_ref7)=>{let{id}=_ref7;return state.accounts.get(state.chats.items.getIn([id,'account']));},(state,_ref8)=>{let{last_message}=_ref8;return state.chat_messages.get(last_message);}],(chat,account,lastMessage)=>{if(!chat||!account)return null;return chat.withMutations(map=>{// @ts-ignore
map.set('account',account);// @ts-ignore
map.set('last_message',lastMessage);});});};export const makeGetReport=()=>{const getStatus=makeGetStatus();return createSelector([(state,id)=>state.admin.reports.get(id),(state,id)=>{var _state$admin$reports$;return state.accounts.get(((_state$admin$reports$=state.admin.reports.get(id))===null||_state$admin$reports$===void 0?void 0:_state$admin$reports$.account)||'');},(state,id)=>{var _state$admin$reports$2;return state.accounts.get(((_state$admin$reports$2=state.admin.reports.get(id))===null||_state$admin$reports$2===void 0?void 0:_state$admin$reports$2.target_account)||'');},// (state: RootState, id: string) => state.accounts.get(state.admin.reports.get(id)?.action_taken_by_account || ''),
// (state: RootState, id: string) => state.accounts.get(state.admin.reports.get(id)?.assigned_account || ''),
(state,id)=>{var _state$admin$reports$3;return ImmutableList(fromJS((_state$admin$reports$3=state.admin.reports.get(id))===null||_state$admin$reports$3===void 0?void 0:_state$admin$reports$3.statuses)).map(statusId=>state.statuses.get(normalizeId(statusId))).filter(s=>s).map(s=>getStatus(state,s.toJS()));}],(report,account,targetAccount,statuses)=>{if(!report)return null;return report.withMutations(report=>{// @ts-ignore
report.set('account',account);// @ts-ignore
report.set('target_account',targetAccount);// @ts-ignore
report.set('statuses',statuses);});});};const getAuthUserIds=createSelector([state=>state.auth.get('users',ImmutableMap())],authUsers=>{return authUsers.reduce((ids,authUser)=>{try{const id=authUser.get('id');return validId(id)?ids.add(id):ids;}catch{return ids;}},ImmutableOrderedSet());});export const makeGetOtherAccounts=()=>{return createSelector([state=>state.accounts,getAuthUserIds,state=>state.me],(accounts,authUserIds,me)=>{return authUserIds.reduce((list,id)=>{if(id===me)return list;const account=accounts.get(id);return account?list.push(account):list;},ImmutableList());});};const getSimplePolicy=createSelector([state=>state.admin.configs,state=>state.instance.pleroma.getIn(['metadata','federation','mrf_simple'],ImmutableMap())],(configs,instancePolicy)=>{return instancePolicy.merge(ConfigDB.toSimplePolicy(configs));});const getRemoteInstanceFavicon=(state,host)=>(state.accounts.find(account=>getDomain(account)===host,null)||ImmutableMap()).getIn(['pleroma','favicon']);const getRemoteInstanceFederation=(state,host)=>getSimplePolicy(state).map(hosts=>hosts.includes(host));export const makeGetHosts=()=>{return createSelector([getSimplePolicy],simplePolicy=>{return simplePolicy.deleteAll(['accept','reject_deletes','report_removal']).reduce((acc,hosts)=>acc.union(hosts),ImmutableOrderedSet()).sort();});};export const makeGetRemoteInstance=()=>{return createSelector([(_state,host)=>host,getRemoteInstanceFavicon,getRemoteInstanceFederation],(host,favicon,federation)=>{return ImmutableMap({host,favicon,federation});});};export const makeGetStatusIds=()=>createSelector([(state,_ref9)=>{let{type,prefix}=_ref9;return getSettings(state).get(prefix||type,ImmutableMap());},(state,_ref10)=>{let{type}=_ref10;return state.timelines.getIn([type,'items'],ImmutableOrderedSet());},state=>state.statuses],(columnSettings,statusIds,statuses)=>{return statusIds.filter(id=>{const status=statuses.get(id);if(!status)return true;return!shouldFilter(status,columnSettings);});});